import React from "react";
import Button from "@mui/material/Button";
import ItemDialog from "./ItemDialog";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import InventoryItemsContainer from "./InventoryItemsContainer";
import ImagesPreviewer from "./ImagesPreviewer";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { properCase } from "./utils";
import useMediaQuery from "@mui/material/useMediaQuery";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import { CSVLink } from "react-csv";

const dayjs = require("dayjs");
const asleep = (m) => new Promise((r) => setTimeout(r, m));
const SLEEP_TIME_IN_MSEC = 2000;

export default function TopPanel(props) {
  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Typography
              component="h3"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1, paddingBottom: "12px" }}
            >
              Matches Found
            </Typography>

            <InventoryItemsContainer
              savedItems={props.savedItems}
              handleOpenEditDialog={() => console.log("noop")}
              handleViewImages={props.handleViewImages}
              handleOpenDeleteDialog={() => console.log("noop")}
              loading={props.loading}
              mallKey={props.mallKey}
            />
            <ImagesPreviewer
              viewImages={props.viewImages}
              handleViewImages={props.handleViewImages}
            />
          </Paper>
        </Grid>
      </Container>
    </>
  );
}
