import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Image } from "mui-image";
import { visuallyHidden } from "@mui/utils";
import Box from "@mui/material/Box";
import TableSortLabel from "@mui/material/TableSortLabel";
import CircularProgress from "@mui/material/CircularProgress";
import CircularIndeterminate from "./CircularIndeterminate";

const cacheKeyPrefix = "mtb_20231015";

export default function InventoryItems(props) {

  const defaultSortOrder = localStorage.getItem(`${cacheKeyPrefix}_sortOrder`) || "desc";
  const defaultOrderBy = localStorage.getItem(`${cacheKeyPrefix}_orderBy`) || "yearMonth";

  const [order, setOrder] = React.useState(defaultSortOrder);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);

  const headCells = [
    {
      id: "yearMonth",
      numeric: true,
      disablePadding: true,
      label: "Year-Month",
    },
    {
      id: "source",
      numeric: false,
      disablePadding: true,
      label: "Source",
    },
    // {
    //   id: "data",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Data",
    // },
    {
      id: "image",
      numeric: false,
      disablePadding: false,
      label: "Flyer",
    },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    localStorage.setItem(`${cacheKeyPrefix}_sortOrder`, isAsc ? "desc" : "asc");
    localStorage.setItem(`${cacheKeyPrefix}_orderBy`, property);
  };

  const addOneMonth = (yearMonth) => {
    let [year, month] = yearMonth.split("-");
    if (month === "12") {
      year = parseInt(year) + 1;
      month = "1";
    } else {
      month = parseInt(month) + 1;
    }
    return `${year}-${month}`
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const visibleSortedItems = props.savedItems
    .sort(getComparator(order, orderBy));


  return (
    <>
      <>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={"center"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                    sx={{ whiteSpace: "nowrap", fontWeight: "bold", fontSize: "1rem" }}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(e) => handleRequestSort(e, headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc" ? "sorted descending" : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleSortedItems.map((item, idx) => (
                <TableRow
                  key={`${item.itemNumber}-${idx}`}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="center" sx={{fontSize: "1rem"}}>
                    {addOneMonth(item.yearMonth)}
                  </TableCell>
                  <TableCell component="th" scope="row" sx={{textAlign: "center", fontSize: "1rem"}}>
                    {item.source.replaceAll("-", " ").replace("Costco ", "")}
                  </TableCell>
                  <TableCell>
                    <Image
                      key={`imageURL-${idx}`}
                      src={item.imageSource}
                      width="100px"
                      onClick={() => {
                        props.handleViewImages([item.imageSource]);
                      }}
                      duration={0}
                      showLoading={<CircularProgress />}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {props.loading ? <CircularIndeterminate style={{ position: "absolute", top: 0, left: 0 }} /> : null}
      </>
    </>
  );
}
