import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Container from "@mui/material/Container";
import CardActions from "@mui/material/CardActions";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { properCase } from "./utils";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Webcam from "react-webcam";
import Switch from "@mui/material/Switch";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";

const dayjs = require("dayjs");

export default function ItemDialog(props) {
  const [shouldShowWebcam, setShouldShowWebcam] = useState(false);
  const [images, setImages] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);

  const [itemDepositDate, setItemDepositDate] = useState(dayjs());
  const [itemNumber, setItemNumber] = useState("");
  const [itemPledgorName, setItemPledgorName] = useState("");
  const [itemDetails, setItemDetails] = useState("");
  const [itemDepositPrice, setItemDepositPrice] = useState("");
  const [itemDateNoPicture, setItemDateNoPicture] = useState("");
  const [itemRecorder, setItemRecorder] = useState("");
  const [itemDateOfRedemption, setItemDateOfRedemption] = useState(dayjs().add(5, "month"));
  const [itemRedemptionInfo, setItemRedemptionInfo] = useState("");
  const [itemDeleter, setItemDeleter] = useState("");
  const [itemIsArchived, setItemIsArchived] = useState(false);

  useEffect(() => {
    if (props.editingItem !== null) {
      setImages(props.editingItem.images);
      setImageURLs(props.editingItem.imageURLs);
      setItemDepositDate(props.editingItem.itemDepositDate);
      setItemNumber(props.editingItem.itemNumber);
      setItemPledgorName(props.editingItem.itemPledgorName);
      setItemDetails(props.editingItem.itemDetails);
      setItemDepositPrice(props.editingItem.itemDepositPrice);
      setItemDateNoPicture(props.editingItem.itemDateNoPicture);
      setItemRecorder(props.editingItem.itemRecorder);
      setItemDateOfRedemption(props.editingItem.itemDateOfRedemption);
      setItemRedemptionInfo(props.editingItem.itemRedemptionInfo);
      setItemDeleter(props.editingItem.itemDeleter);
      setItemIsArchived(props.editingItem.itemIsArchived);
    }
  }, [props.editingItem]);

  useEffect(() => {
    if (images.length < 1) return;
    const newImageURLs = images.map((image) => {
      return URL.createObjectURL(image);
    });
    setImageURLs(newImageURLs);
  }, [images]);

  useEffect(() => {
    setItemDateOfRedemption(itemDepositDate.add(5, "month"));
  }, [itemDepositDate]);

  const onImageChange = (e) => {
    setImages([...e.target.files]);
  };

  const handleDeleteImage = (imageToDelete) => {
    const newImageURLs = imageURLs.filter((imageURL) => imageURL !== imageToDelete);
    setImageURLs(newImageURLs);
  };

  const doAdd = async () => {
    handleClose();
    await props.handleSaveItem(
      {
        imageURLs,
        images,
        itemDepositDate,
        itemNumber,
        itemPledgorName,
        itemDetails,
        itemDepositPrice,
        itemDateNoPicture,
        itemRecorder,
        itemDateOfRedemption,
        itemRedemptionInfo,
        itemDeleter,
        itemIsArchived,
      },
      props.hasOwnProperty("editingIndex") ? props.editingIndex : null
    );
    if (props.editingItem !== null) {
      props.setSnackbarMessage("Item Edited");
    } else {
      props.setSnackbarMessage("Item Added");
    }
    props.handleOpenSnackbar();
    clearCachedItem();
  };

  const clearCachedItem = () => {
    setItemDepositDate(dayjs());
    setItemNumber("");
    setItemPledgorName("");
    setItemDetails("");
    setItemDepositPrice("");
    setItemDateNoPicture("");
    setItemRecorder("");
    setItemDateOfRedemption(dayjs());
    setItemRedemptionInfo("");
    setItemDeleter("");
    setImages([]);
    setImageURLs([]);
    setItemIsArchived(false);
    setShouldShowWebcam(false);
  };

  const dataUrlToFile = async (dataUrl, fileName) => {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: "image/png" });
  };

  const handleClose = () => {
    setShouldShowWebcam(false);
    props.handleClose();
  };

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };
  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle>{`${props.title}`}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ paddingTop: props.editingItem !== null ? "0px" : "8px" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TextField
            required
            id="mallKey"
            name="mallKey"
            label="Mall ID"
            value={properCase(props.mallKey)}
            fullWidth
            variant="standard"
            disabled
            sx={{ pb: props.editingItem !== null ? "12px" : "24px" }}
          />
          {props.editingItem !== null ? (
            <>
              <FormControlLabel
                sx={{ pb: "12px" }}
                label="Archive Item"
                control={<Checkbox checked={itemIsArchived} onChange={(e) => setItemIsArchived(e.target.checked)} />}
              />
              {itemIsArchived === true ? (
                <>
                  <Box component="div" sx={{ padding: "12px", border: "2px dashed red" }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          id="itemDeleter"
                          name="itemDeleter"
                          label="ผู้ลบ"
                          value={itemDeleter}
                          onChange={(e) => setItemDeleter(e.target.value)}
                          fullWidth
                          variant="standard"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <br />
                </>
              ) : null}
            </>
          ) : null}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <DesktopDatePicker
                id="itemDepositDate"
                name="itemDepositDate"
                label="วันที่ฝาก"
                inputFormat="DD/MM/YYYY"
                value={itemDepositDate}
                onChange={(newValue) => setItemDepositDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="itemNumber"
                type="number"
                name="itemNumber"
                label="No"
                value={itemNumber}
                onChange={(e) => setItemNumber(parseInt(e.target.value))}
                fullWidth
                variant="standard"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                id="itemPledgorName"
                name="itemPledgorName"
                label="ชื่อ-นามสกุล เลขบัตร ปชช"
                value={itemPledgorName}
                onChange={(e) => setItemPledgorName(e.target.value)}
                fullWidth
                variant="standard"
                multiline
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="itemDetails"
                name="itemDetails"
                label="รายละเอียด"
                value={itemDetails}
                onChange={(e) => setItemDetails(e.target.value)}
                fullWidth
                variant="standard"
                multiline
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="itemDepositPrice"
                name="itemDepositPrice"
                type="number"
                label="ราคาฝาก"
                value={itemDepositPrice}
                onChange={(e) => parseFloat(setItemDepositPrice(e.target.value))}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="itemDateNoPicture"
                name="itemDateNoPicture"
                label="วันที่/Noรูป"
                value={itemDateNoPicture}
                onChange={(e) => setItemDateNoPicture(e.target.value)}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="itemRecorder"
                name="itemRecorder"
                label="ผู้บันทึก"
                value={itemRecorder}
                onChange={(e) => setItemRecorder(e.target.value)}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DesktopDatePicker
                id="itemDateOfRedemption"
                name="itemDateOfRedemption"
                label="วันที่ไก่ต่อ ดอก"
                inputFormat="DD/MM/YYYY"
                value={itemDateOfRedemption}
                onChange={(newValue) => setItemDateOfRedemption(newValue)}
                renderInput={(params) => <TextField {...params} />}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="itemRedemptionInfo"
                name="itemRedemptionInfo"
                label="ข้อมูลการไถ่-ต่อดอก"
                value={itemRedemptionInfo}
                onChange={(e) => setItemRedemptionInfo(e.target.value)}
                fullWidth
                variant="standard"
                multiline
              />
            </Grid>
            {/*<Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox color="secondary" name="saveAddress" value="yes" />}
                label="Use this address for payment details"
              />
            </Grid>*/}
            <Grid item xs={12} sm={6}>
              <input hidden id="raised-button-file" type="file" multiple accept="image/*" onChange={onImageChange} />
              <label htmlFor="raised-button-file">
                <Button variant="contained" component="span" endIcon={<DriveFolderUploadIcon />}>
                  {"Add Image(s) from Disk"}
                </Button>
              </label>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={shouldShowWebcam}
                    onChange={(event) => setShouldShowWebcam(event.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Show Webcam"
              />
            </Grid>
          </Grid>

          <div style={{ textAlign: "center", paddingTop: "8px" }}>
            {shouldShowWebcam ? (
              <Webcam
                audio={false}
                height={270}
                screenshotFormat="image/jpeg"
                width={480}
                videoConstraints={videoConstraints}
              >
                {({ getScreenshot }) => (
                  <div>
                    <Button
                      variant="contained"
                      component="span"
                      endIcon={<AddAPhotoIcon />}
                      onClick={() => {
                        const imageSrc = getScreenshot();
                        dataUrlToFile(imageSrc, Math.floor(Date.now() / 1000).toString() + ".png").then((file) => {
                          setImages([file]);
                        });
                      }}
                    >
                      {"Capture Photo"}
                    </Button>
                  </div>
                )}
              </Webcam>
            ) : null}
          </div>
          <hr style={{ borderTop: "dashed", backgroundColor: "transparent" }} />
          <Container sx={{ py: 2 }} maxWidth="md">
            <Grid container spacing={4}>
              {imageURLs &&
                imageURLs.map((imageSrc, idx) => {
                  return (
                    <Grid item key={`item-${idx}`}>
                      <Card sx={{ height: "100%", width: "200px", display: "flex", flexDirection: "column" }}>
                        <CardMedia
                          component="div"
                          sx={{
                            // 16:9
                            pt: "100%",
                          }}
                          image={imageSrc}
                        />
                        <CardActions>
                          <Grid container spacing={1}>
                            <Grid item sx={{ textAlign: "center" }} xs={12} sm={6}>
                              <Button size="small" onClick={() => props.handleViewImages([imageSrc])}>
                                Enlarge
                              </Button>
                            </Grid>
                            <Grid item sx={{ textAlign: "center" }} xs={12} sm={6}>
                              <Button size="small" onClick={() => handleDeleteImage(imageSrc)}>
                                Remove
                              </Button>
                            </Grid>
                          </Grid>
                        </CardActions>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>
          </Container>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={doAdd}>{props.editingItem === null ? "Add" : "Edit"}</Button>
      </DialogActions>
    </Dialog>
  );
}
