import React from "react";
import InventoryItems from "./InventoryItems";
import Paper from "@mui/material/Paper";

export default function InventoryItemsContainer(props) {

  return (
    <Paper elevation={1}>
      <InventoryItems
        savedItems={props.savedItems}
        handleOpenEditDialog={props.handleOpenEditDialog}
        handleViewImages={props.handleViewImages}
        handleOpenDeleteDialog={props.handleOpenDeleteDialog}
        loading={props.loading}
        mallKey={props.mallKey}
      />
    </Paper>
  );
}
