import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import TopPanel from "./TopPanel";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import AdsComponent from "./AdsComponent";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import useMediaQuery from "@mui/material/useMediaQuery";

const defaultTheme = createTheme();

const monthShortNameToMonthNumber = {
  Jan: "1",
  Feb: "2",
  Mar: "3",
  Apr: "4",
  May: "5",
  Jun: "6",
  Jul: "7",
  Aug: "8",
  Sep: "9",
  Oct: "10",
  Nov: "11",
  Dec: "12",
};

export default function Dashboard() {
  const [searchInput, setSearchInput] = React.useState("listerine");
  const [savedItems, setSavedItems] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const nonMobileDevices = useMediaQuery("(min-width:600px)");

  React.useEffect(() => {
    loadItems();
    loadSources();
    loadPages();
  }, []);

  const addOneMonth = (yearMonth) => {
    let [year, month] = yearMonth.split("-");
    if (month === "12") {
      year = parseInt(year) + 1;
      month = "1";
    } else {
      month = parseInt(month) + 1;
    }
    return [year, month];
  };

  const addOneMonthFormatted = (yearMonth) => {
    const [year, month] = addOneMonth(yearMonth);
    return `${year}-${month}`;
  };

  const getChartData = (transformedItems) => {
    const data = [
      {
        name: "Jan",
        2020: 0,
        2021: 0,
        2022: 0,
        2023: 0,
        2024: 0,
      },
      {
        name: "Feb",
        2020: 0,
        2021: 0,
        2022: 0,
        2023: 0,
        2024: 0,
      },
      {
        name: "Mar",
        2020: 0,
        2021: 0,
        2022: 0,
        2023: 0,
        2024: 0,
      },
      {
        name: "Apr",
        2020: 0,
        2021: 0,
        2022: 0,
        2023: 0,
        2024: 0,
      },
      {
        name: "May",
        2020: 0,
        2021: 0,
        2022: 0,
        2023: 0,
        2024: 0,
      },
      {
        name: "Jun",
        2020: 0,
        2021: 0,
        2022: 0,
        2023: 0,
        2024: 0,
      },
      {
        name: "Jul",
        2020: 0,
        2021: 0,
        2022: 0,
        2023: 0,
        2024: 0,
      },
      {
        name: "Aug",
        2020: 0,
        2021: 0,
        2022: 0,
        2023: 0,
        2024: 0,
      },
      {
        name: "Sep",
        2020: 0,
        2021: 0,
        2022: 0,
        2023: 0,
        2024: 0,
      },
      {
        name: "Oct",
        2020: 0,
        2021: 0,
        2022: 0,
        2023: 0,
        2024: 0,
      },
      {
        name: "Nov",
        2020: 0,
        2021: 0,
        2022: 0,
        2023: 0,
        2024: 0,
      },
      {
        name: "Dec",
        2020: 0,
        2021: 0,
        2022: 0,
        2023: 0,
        2024: 0,
      },
    ];

    transformedItems.forEach((item) => {
      const [year, month] = addOneMonth(item.yearMonth);
      data[month - 1][year] += 1;
    });
    return data;
  };

  const [chartData, setChartData] = React.useState(getChartData([]));
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      loadItems();
    }
  };

  const [viewImages, setViewImages] = React.useState([]);
  const handleViewImages = (images) => {
    setViewImages(images);
  };

  const [sources, setSources] = React.useState([]);
  const loadSources = async () => {
    const response = await fetch("/api/v1/sources", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const sources = (await response.json()).results;
    const transformedSources = sources.reduce((result, source) => {
      const year = source["year"];
      const month = source["month"];
      return {
        ...result,
        [`${year}-${month}`]: [...(result[`${year}-${month}`] || []), source],
      };
    }, {});
    setSources(transformedSources);
  };

  const [pages, setPages] = React.useState([]);
  const loadPages = async () => {
    const response = await fetch("/api/v1/pages", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const pages = (await response.json()).results;
    const transformedPages = pages.reduce((result, page) => {
      return {
        ...result,
        [page["name"]]: [...(result[page["name"]] || []), page],
      };
    }, {});
    setPages(transformedPages);
  };

  const loadItems = async () => {
    const response = await fetch(
      "/api/v1/items?" + new URLSearchParams({ query: searchInput }),
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      },
    );
    const items = (await response.json()).results;

    const transformedItems = items.map((item) => {
      return {
        source: item.source,
        imageSource: item.imageSource,
        yearMonth: item.yearMonth,
      };
    });

    setSavedItems(transformedItems);
    setChartData(getChartData(transformedItems));
    setLoading(false);
    return items;
  };

  const handleBarChartClick = (e, year) => {
    const monthShortName = e.name;
    const monthNumber = monthShortNameToMonthNumber[monthShortName];
    const matchedItems = savedItems.filter((item) => {
      const [itemYear, itemMonth] = addOneMonth(item.yearMonth);
      return `${itemYear}-${itemMonth}` === `${year}-${monthNumber}`;
    });
    if (matchedItems.length >= 0) {
      setViewImages(matchedItems.map((item) => item.imageSource));
    }
  };

  // Side-effect to check if item is on sale right now
  const [activeDeals, setActiveDeals] = React.useState([]);
  React.useEffect(() => {
    const latestSourceKey = Object.keys(sources).sort().reverse()[0];
    let activePageSources;
    if (latestSourceKey !== undefined) {
      activePageSources = savedItems.filter(
        (item) => item.yearMonth === latestSourceKey,
      );
      setActiveDeals(activePageSources);
    }
  }, [sources, savedItems]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MuiAppBar position="absolute">
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Costco Deals Tracker
            </Typography>
          </Toolbar>
        </MuiAppBar>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />

          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <TextField
                id="input-with-icon-textfield"
                placeholder="Search any product name like Huggies, Nature Valley, or Duracell…"
                value={searchInput}
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                  if (e.target.value !== searchInput) {
                    setSearchInput(e.target.value);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={(e) => setSearchInput("")}
                    >
                      <IconButton color="inherit">
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            </Paper>
          </Container>

          {activeDeals.length > 0 ? (
            <Container maxWidth="xl" sx={{ mt: 0, mb: 0 }}>
              <Alert
                icon={<ShoppingCartCheckoutIcon fontSize="inherit" />}
                severity="success"
              >
                <p>
                  {`This product is part of the latest coupon book and may currently be on sale. If you've purchased this product in the past 60 days, Costco will price match and return you the difference. Check the following Costco pages:`}
                </p>
                {activeDeals.map((deal, idx) => (
                  <li
                    style={{ textDecoration: "underline" }}
                    key={`${deal.name}-${idx}`}
                    onClick={() => setViewImages([deal.imageSource])}
                  >
                    {deal.source.replaceAll("-", " ")}
                  </li>
                ))}
                <p>
                  {`See Customer Returns at your local store and bring your receipt that shows the purchase of the product within the last 60 days. Note, you can access receipts online if you've thrown it out.`}
                </p>
              </Alert>
            </Container>
          ) : null}

          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* Chart */}
              <Grid item xs={12} md={7} lg={8}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "scroll",
                    alignItems: nonMobileDevices ? "center" : "left",
                  }}
                >
                  <Typography
                    component="h3"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                  >
                    Historical Trend
                  </Typography>

                  <BarChart
                    width={700}
                    height={300}
                    data={chartData}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey="2020"
                      stackId="a"
                      fill="#8884d8"
                      onClick={(e) => handleBarChartClick(e, "2020")}
                    />
                    <Bar
                      dataKey="2021"
                      stackId="a"
                      fill="#82ca9d"
                      onClick={(e) => handleBarChartClick(e, "2021")}
                    />
                    <Bar
                      dataKey="2022"
                      stackId="a"
                      fill="#ffc658"
                      onClick={(e) => handleBarChartClick(e, "2022")}
                    />
                    <Bar
                      dataKey="2023"
                      stackId="a"
                      fill="#FF8042"
                      onClick={(e) => handleBarChartClick(e, "2023")}
                    />
                    <Bar
                      dataKey="2024"
                      stackId="a"
                      fill="#0088FE"
                      onClick={(e) => handleBarChartClick(e, "2024")}
                    />
                  </BarChart>
                </Paper>
              </Grid>
              {/* List of Circulars */}
              <Grid item xs={12} md={5} lg={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 364,
                    margin: "20 30 20 5",
                  }}
                >
                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                      position: "relative",
                      overflow: "auto",
                      "& ul": { padding: 0 },
                    }}
                    subheader={<li />}
                  >
                    <Typography
                      component="h3"
                      variant="h6"
                      color="inherit"
                      noWrap
                      sx={{ flexGrow: 1 }}
                    >
                      Past Circulars
                    </Typography>
                    {Object.keys(sources)
                      .sort()
                      .reverse()
                      .map((yearMonth) => (
                        <li key={`section-${yearMonth}`}>
                          <ul>
                            <ListSubheader>{`${addOneMonthFormatted(
                              yearMonth,
                            )}`}</ListSubheader>
                            {sources[yearMonth].map((item) => (
                              <ListItem key={`item-${yearMonth}-${item.name}`}>
                                <ListItemButton
                                  onClick={() => {
                                    // TODO: Fix the sort on pages
                                    // Also, fix mobile stepper rerendering itself
                                    const imageSources = pages[item.name]
                                      .map((page) => page.imageSource)
                                      .sort();
                                    setViewImages(imageSources);
                                  }}
                                >
                                  <ListItemText primary={`${item.name}`} />
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </ul>
                        </li>
                      ))}
                  </List>
                </Paper>
              </Grid>
            </Grid>
          </Container>

          <TopPanel
            savedItems={savedItems}
            loading={loading}
            handleOpenSnackbar={() => console.log("noop")}
            setSnackbarMessage={() => console.log("noop")}
            searchInput={searchInput}
            viewImages={viewImages}
            handleViewImages={handleViewImages}
          />

          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                overflow: "scroll",
                alignItems: nonMobileDevices ? "center" : "left",
              }}
            >
              <AdsComponent
                dataAdSlot="8672866783"
                isDesktop={nonMobileDevices}
              />
            </Paper>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
