import React, { useEffect } from 'react';

const AdsComponent = (props) => {
  const { dataAdSlot, isDesktop } = props;

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
    catch (e) {
      console.warn(e);
    }
  },[]);

  return (
    <>
      <ins className="adsbygoogle"
        style={{ display: "block", width: isDesktop ? "800px" : "400px" }}
        data-ad-client="ca-pub-2439709379985933"
        data-ad-slot={dataAdSlot}
        data-ad-format="auto"
        data-full-width-responsive="true">
      </ins>
    </>
  );
};

export default AdsComponent;
