import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Dialog from "@mui/material/Dialog";
import { Image } from "mui-image";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function ImagesPreviewer(props) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = props.viewImages.length > 0 ? props.viewImages.length : 0;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onClose = () => {
    props.handleViewImages([]);
    setActiveStep(0);
  };

  const onKeyDown = (e) => {
    switch (e.key) {
      case "ArrowLeft":
        if (activeStep <= 0) {
          setActiveStep(maxSteps - 1);
        } else {
          handleBack();
        }
        break;
      case "ArrowRight":
        if (activeStep >= maxSteps - 1) {
          setActiveStep(0);
        } else {
          handleNext();
        }
        break;
      default:
        // eslint-disable-next-line
        const _a = 1;
    }
  };

  return (
    <Dialog
      maxWidth={"xs"}
      fullWidth={true}
      onClose={onClose}
      onKeyDown={onKeyDown}
      open={props.viewImages.length > 0 ? true : false}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ width: "100%", margin: "auto", flexGrow: 1 }}>
        {/*
        <Paper
          square
          elevation={0}
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: 50,
            pl: 2,
            bgcolor: 'background.default',
          }}
        >
          <Typography>{steps[activeStep].label}</Typography>
        </Paper>
        */}
        <Box sx={{ width: "100%", p: 2 }}>
          {props.viewImages.length === 0 ? null : (
            <Image key={`img-${activeStep}`} src={props.viewImages[activeStep]} duration={0} />
          )}
        </Box>
        <MobileStepper
          variant="text"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
              Next
              {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              Back
            </Button>
          }
        />
      </Box>
    </Dialog>
  );
}
